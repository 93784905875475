import React from "react";

const FAQ = () => {
	const style = { textDecoration: "underline" };

	return (
		<div className="section">
			<a id="faq">
				<h2>FAQ - Frequently Asked Questions</h2>
			</a>
			<div className="faq-text">
				<div className="question">
					<div className="big-q">Q.</div> How do I get an Ontario Hunting
					License?
				</div>
				<div className="answer">
					<div className="big-a">A.</div>{" "}
					<div>
						The first step is to complete an Ontario Hunter Education course
						approved by the Ministry of Natural Resources, like the one we
						offer. <br />
						<br />
						After completing the course and passing the test, you will be given
						a document to prove that you graduated. Take this document and two
						pieces of government-issued photo ID into a Service Ontario location
						that deals with hunting licenses. They will issue you an Ontario
						Outdoors Card which includes a hunting license. You should get a
						temporary license the same day, and the plastic card will be mailed
						to you later.
						<br /> <br />
						Please make sure that the Service Ontario you go to deals with
						hunting licenses. You can use the Service Ontario website to search
						for a location by clicking{" "}
						<a
							style={style}
							href="https://www.ontario.ca/locations/serviceontario"
						>
							here
						</a>
						.
					</div>
				</div>
				<div className="question">
					<div className="big-q">Q.</div> Can I manage my Ontario hunting
					products, like game tags, online?
				</div>
				<div className="answer">
					<div className="big-a">A.</div>{" "}
					<div>
						The Ministry of Natural Resources and Forestry have a website where
						you can create an account, and use it to manage your Ontario hunting
						products. You can access this website by clicking{" "}
						<a
							style={style}
							href="https://www.huntandfishontario.com/Account/Login"
						>
							here
						</a>
						.
					</div>
				</div>
				<div className="question">
					<div className="big-q">Q.</div> How long does it take to get a
					Possession Acquisition License (PAL)?
				</div>
				<div className="answer">
					<div className="big-a">A.</div>{" "}
					<div>
						Right now it takes about four months to get your PAL. More
						information on this process can be found on the{" "}
						<a
							style={style}
							href="https://www.rcmp-grc.gc.ca/en/firearms/licensing"
						>
							RCMP's website
						</a>{" "}
						.<br />
						<br />
						The RCMP website also provides various firearms-related application
						forms, which you can find{" "}
						<a
							style={style}
							href="https://www.rcmp-grc.gc.ca/en/firearms/firearms-forms#f1"
						>
							here
						</a>
						.<br />
						<br />
						You can check on the status of your PAL application{" "}
						<a
							style={style}
							href="https://www.services.rcmp-grc.gc.ca/chooser-eng.html?"
						>
							here
						</a>
						.
					</div>
				</div>
				<div className="question">
					<div className="big-q">Q.</div> Can I apply for my Possession
					Acquisition License (PAL) online?
				</div>
				<div className="answer">
					<div className="big-a">A.</div>{" "}
					<div>
						Yes, you can use the RCMP's website to apply for your PAL online.
						You can access this service by clicking{" "}
						<a
							style={style}
							href="https://www.rcmp-grc.gc.ca/en/apply-a-firearms-licence"
						>
							here
						</a>
						.
					</div>
				</div>
				<div className="question">
					<div className="big-q">Q.</div> Where can I hunt in Ontario?
				</div>
				<div className="answer">
					<div className="big-a">A.</div>{" "}
					<div>
						While there is no single answer to this question, we can help you
						get started. <br />
						<br /> You can check the{" "}
						<a
							style={style}
							href="https://www.ontario.ca/page/crown-land-use-policy-atlas"
						>
							Crown Land Ontario Atlas
						</a>{" "}
						to find Crown Land to hunt on. <br />
						<br />
						Simcoe County Forests also allows OFAH members in good standing to
						hunt on some of their land. More information can be found on the{" "}
						<a
							style={style}
							href="https://www.simcoe.ca/Forestry/Pages/Hunting-and-Fishing.aspx"
						>
							Simcoe County Forests website
						</a>
						. <br />
						<br />
						The{" "}
						<a style={style} href="https://www.mtmconservation.org/">
							M-T-M Conservation Association
						</a>{" "}
						oversees outdoor activities including hunting in Marl Lake, Tiny
						Marsh, and Matchedash Bay. More information can be found on their
						website .
					</div>
				</div>
				<div className="question">
					<div className="big-q">Q.</div> How do I become a member of the
					Ontario Federation of Hunters and Anglers (OFAH)?
				</div>
				<div className="answer">
					<div className="big-a">A.</div>{" "}
					<div>
						You can learn more about the OFAH, and purchase a membership, at the{" "}
						<a style={style} href="https://www.ofah.org/membership/">
							OFAH website.
						</a>
					</div>
				</div>
			</div>
			<hr className="section-break" />
		</div>
	);
};

export default FAQ;
