import Header from "./components/Header";
import Main from "./components/Main";
import TopButton from "./components/TopButton";
import "./App.css";
import { React, useState, useEffect } from "react";

function App() {
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const handleClick = (ev) => {
		const dropdown = document.getElementById("dropdown");
		const dropdownContent = document.getElementById("dropdown-content");
		if (!dropdown.contains(ev.target) && !dropdownContent.contains(ev.target)) {
			setDropdownVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener("click", handleClick);
		return () => {
			window.removeEventListener("click", handleClick);
		};
	}, []);

	return (
		<div className="project-body">
			<Header
				dropdownVisible={dropdownVisible}
				setDropdownVisible={setDropdownVisible}
			/>
			<Main setDropdownVisible={setDropdownVisible} />
			<TopButton />
		</div>
	);
}

export default App;
