import LarryCandid from "../assets/Larry-Candid-Portrait.jpg";
import { FiPhone } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";

const ContactInfo = () => {
	const iconStyle = { position: "relative", top: "3px", marginRight: "8px" };
	return (
		<div className="section">
			<a id="contact-info">
				<h2>Contact Information</h2>
			</a>
			<div className="text-and-photo">
				<div className="text">
					For all enquiries, please contact Larry Ferris by phone or email.
					<br />
					<br />
					<ul>
						<li className="email-and-phone" style={{ marginBottom: "30px" }}>
							<div style={{ marginTop: "10px", marginBottom: "10px" }}>
								<FiPhone style={iconStyle} />
								<span>Phone :</span>
							</div>
							{"(705) 529 - 6364"}
						</li>
						<li className="email-and-phone">
							<div style={{ marginBottom: "10px" }}>
								<AiOutlineMail style={iconStyle} />
								<span>Email :</span>
							</div>
							<a href="mailto:ferrishuntersafetytraining@gmail.com">
								ferrishuntersafetytraining@gmail.com
							</a>
						</li>
					</ul>
				</div>
				<img
					className="contact-photo main-pics"
					src={LarryCandid}
					alt="Larry Ferris"
				/>
			</div>
			<hr className="section-break" />
		</div>
	);
};

export default ContactInfo;
