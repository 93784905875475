import { React, useState } from "react";

const Header = (props) => {
	const { dropdownVisible, setDropdownVisible } = props;

	const [headerKeepDropdownOpen, setHeaderKeepDropdownOpen] = useState(false);
	const [menuKeepDropdownOpen, setMenuKeepDropdownOpen] = useState(false);

	const dropdownStyles = {
		display: dropdownVisible ? "block" : "none",
	};

	// Click-based dropdown toggle for mobile devices
	const handleDropdownToggle = () => {
		if (dropdownVisible) {
			setDropdownVisible(false);
		} else {
			setDropdownVisible(true);
		}
	};

	// Click outside of dropdown to close it (mobile devices)
	const handleNavClick = () => {
		setDropdownVisible(false);
	};

	// Activate dropdown on hover
	const handleMouseOverDropdown = () => {
		setDropdownVisible(true);
	};

	// Prevent dropdown from closing while cursor is on header
	const handleHeaderKeepDropdownOpen = () => {
		setHeaderKeepDropdownOpen(true);
	};

	const handleHeaderAllowDropdownClose = () => {
		setHeaderKeepDropdownOpen(false);
	};

	// Close dropdown after hover
	const handleMouseLeaveDropdown = () => {
		if (!headerKeepDropdownOpen) {
			setDropdownVisible(false);
		}
	};

	return (
		<div
			className="header"
			onMouseOver={() => handleHeaderKeepDropdownOpen()}
			onMouseLeave={() => handleHeaderAllowDropdownClose()}
		>
			<span className="company-identity">F.H.S.T</span>
			<div
				className="dropdown"
				id="dropdown"
				onMouseLeave={() => handleMouseLeaveDropdown()}
				onMouseOver={() => handleMouseOverDropdown()}
			>
				<button className="dropdown-button" onClick={handleDropdownToggle}>
					Site Navigation
				</button>
				<div
					className="dropdown-content"
					id="dropdown-content"
					style={dropdownStyles}
				>
					<a href="#mission-statement" onClick={handleNavClick}>
						<div className="dropdown-nav-element">Mission Statement</div>
					</a>
					<a href="#upcoming-courses" onClick={handleNavClick}>
						<div className="dropdown-nav-element">Upcoming Courses</div>
					</a>
					<a href="#courses-offered" onClick={handleNavClick}>
						<div className="dropdown-nav-element">Courses Offered</div>
					</a>
					<a href="#contact-info" onClick={handleNavClick}>
						<div className="dropdown-nav-element">Contact Info</div>
					</a>
					<a href="#faq" onClick={handleNavClick}>
						<div className="dropdown-nav-element">FAQ</div>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Header;
