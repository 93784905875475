import OHEManual from "../assets/OHE-Manual.jpg";

const UpcomingCourses = () => {
	return (
		<div className="section">
			<a id="upcoming-courses">
				<h2>Upcoming Courses</h2>
			</a>
			<div className="text-and-photo">
				<div className="text">
					Courses will be held on the following dates:
					<br />
					<br />
					<br />
					<ul>
						{/* <li>
							October 21st, 2024 - Hunting Only (
							<span style={{ color: "rgb(250,2,4)" }}>FULL</span>)
						</li>
						<br /> */}
						<li>October 12th and 13th, 2024 - One-Stop</li>
						<br />
						{/* <li>
							APR 6th and 7th, 2023 - One-Stop (
							<span style={{ color: "rgb(255,191,0)" }}>NEARLY FULL</span>)
						</li>
						<li>April 6th and 7th, 2024 - One-Stop</li> */}
						{/* <li>
							APR 6th and 7th, 2023 - One-Stop (
							<span style={{ color: "rgb(250,2,4)" }}>FULL</span>)
						</li>
						<br /> */}
						{/* <br />
						<li>More course dates to be annouced soon!</li>
						<br /> */}
					</ul>
					<br />
					<p style={{ textIndent: "0px" }}>
						Please <a href="#contact-info">call or email</a> to request a spot,
						or to inquire about prospective course dates.
					</p>
				</div>
				<img
					className="course-manual main-pics"
					src={OHEManual}
					alt="Course Manual"
				/>
			</div>
			<hr className="section-break" />
		</div>
	);
};

export default UpcomingCourses;
