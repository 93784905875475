import WelcomeBox from "./WelcomeBox";
import MissionStatement from "./MissionStatement";
import CoursesOffered from "./CoursesOffered";
import UpcomingCourses from "./UpcomingCourses";
import FAQ from "./FAQ";
import ContactInfo from "./ContactInfo";

const Main = ({ setDropdownVisible }) => {
	return (
		<div onMouseOver={() => setDropdownVisible(false)}>
			<WelcomeBox />
			<div className="main">
				<MissionStatement />
				<UpcomingCourses />
				<CoursesOffered />
				<ContactInfo />
				<FAQ />
				<button className="back-to-top hidden">Back to top</button>
			</div>
		</div>
	);
};

export default Main;
