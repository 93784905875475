import React from "react";

const CoursesOffered = () => {
	return (
		<div className="section">
			<a id="courses-offered">
				<h2>Courses Offered</h2>
			</a>
			<div className="courses-breakdown">
				<span style={{ margin: "0px" }}>We offer two types of courses:</span>
				<div className="text-only-block">
					<ol className="outter-ol">
						<li>
							<span
								style={{
									fontWeight: "700",
									fontStyle: "italic",
								}}
							>
								Hunter Safety and Non-Restricted Firearms ("One Stop"){" "}
							</span>
							--
							<span style={{ margin: "0px 0px 0px 10px" }}>
								{" "}
								Two day course.
								<ol className="inner-ol">
									<li className="courses-li-spacing">
										On day one, Larry Ferris teaches the Hunter Safety course
										approved by the Ministry of Natural Resources. Students take
										the exam that same day, and graduates become eligible for
										their Ontario hunting license.
									</li>
									<li className="courses-li-spacing">
										On day two, Kevin Stroud teaches the non-restricted firearms
										course. Students also take this exam the same day. Graduates
										can then apply to the Chief Firearms Officer of Ontario for
										their Non-Restricted Posession and Acquisition License
										(PAL).
									</li>
									<li className="courses-li-spacing">
										Because of high demand, the One Stop is by far the most
										common course we offer. If you plan on hunting in Ontario
										with a firearm, this is the course for you.
									</li>
								</ol>
							</span>
						</li>
						<br />
						<li>
							<span
								style={{
									fontWeight: "700",
									fontStyle: "italic",
								}}
							>
								Hunter Safety only{" "}
							</span>
							--
							<span style={{ margin: "0px 0px 0px 10px" }}>
								One day course.
								<ol className="inner-ol">
									<li className="courses-li-spacing">
										Larry Ferris takes students through the Hunter Safety
										course, approved by the Ministry of Natural Resources.
										Students take the exam that same day. Graduates become
										eligible for their hunting license, which only allows them
										to hunt with bows.
									</li>
									<li className="courses-li-spacing">
										If you wish to only hunt with bows, this is the course for
										you. Because this course is less popular, we only offer it
										occasionally.
									</li>
									<li className="courses-li-spacing">
										Please note that hunters who wish to hunt with rifles or
										shotguns must have their PAL, and should consider taking the
										One-Stop course.
									</li>
								</ol>
							</span>
						</li>
					</ol>
					<br />
					<div>
						Each course we offer usually has 12 seats available, and a deposit
						is required to hold a seat.
					</div>
				</div>
			</div>
			<hr className="section-break" />
		</div>
	);
};

export default CoursesOffered;
