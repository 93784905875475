import FHSTLogo from "../assets/FerrisHunterSafetyTraining-Final.png";

const WelcomeBox = () => {
	const style = { display: "none" };
	return (
		<div className="welcome">
			<h1 className="site-title" style={style}>
				<a id="welcome">Ferris Hunter Safety Training</a>
			</h1>
			<img className="hero-img" src={FHSTLogo} alt="Company logo" />
			<h2
				className="mission-statement"
				style={{ color: "rgb(245,245,245,0.85)" }}
			>
				Keeping hunters safe through better education.
			</h2>
		</div>
	);
};

export default WelcomeBox;
