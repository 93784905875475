import React from "react";

const TopButton = () => {
	const [topButtonVisible, setTopButtonVisible] = React.useState(false);

	// Toggle visibility of TopButton based page position
	const monitorScroll = () => {
		if (
			document.body.scrollTop > 20 ||
			document.documentElement.scrollTop > 20
		) {
			setTopButtonVisible(true);
		} else {
			setTopButtonVisible(false);
		}
	};

	// Return window to top of page
	const handleBackToTop = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	};

	window.onscroll = function () {
		monitorScroll();
	};

	let topBtnStyle = {
		display: topButtonVisible ? "block" : "none",
	};

	return (
		<a href="#welcome">
			<button
				onClick={handleBackToTop}
				id="top-button"
				className="top-button"
				title="Go to top"
				style={topBtnStyle}
			>
				Top
			</button>
		</a>
	);
};

export default TopButton;
