import BearPic from "../assets/bear-pic-1.jpg";

const MissionStatement = () => {
	return (
		<div className="section">
			<a id="mission-statement">
				<h2>The Ferris Difference</h2>
			</a>
			<div className="text-and-photo">
				<div className="text">
					<p>
						At Ferris Hunter Safety Training, we believe in providing the very
						best Ontario Hunter Education course we can. Because hunting is a
						timeless part of Canadian culture, and it requires great
						responsibility. Hunters are responsible for their safety, the safety
						of others, and obeying the laws of the land. So in Ontario, every
						hunter must complete a Hunter Education Course approved by the
						Ministry of Natural Resources (MNR).{" "}
					</p>
					<br />
					<p>
						That's why Larry Ferris strives to provide the best learning
						experience he can. His goal is not just that his students pass the
						test, but that they really understand the material. When possible,
						he also invites a conservation officer into the classroom to help
						students learn how to properly interact with them.
					</p>
					<br />
					<p>
						Larry goes the extra mile, because he knows that safety matters. He
						knows that wildlife conservation matters. And he knows that informed
						hunters make the best hunters. We invite you to{" "}
						<span style={{ fontStyle: "italic", fontWeight: "600" }}>
							experience the Ferris difference.
						</span>
					</p>
				</div>
				<img className="bear-pic main-pics" src={BearPic} alt="Sample" />
			</div>
			<hr className="section-break" />
		</div>
	);
};

export default MissionStatement;
